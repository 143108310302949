<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-auto">
        <h1>{{ $t('faq.support') }}</h1>
      </div>
      <div class="col-12 col-auto">
        <button class="btn" @click="askModal">{{ $t('faq.ask_question') }}</button>
      </div>
    </div>
    <div class="sep _h10"></div>
    <ul class="tabs max-770 _mob100">
      <li>
        <button
          :class="{ active: activeTab === 'mainQuestion' }"
          @click="selectTab('mainQuestion')"
        >
          {{ $t('faq.main_questions') }}
        </button>
      </li>
      <li>
        <button
          :class="{ active: activeTab === 'technicalQuestion' }"
          @click="selectTab('technicalQuestion')"
        >
          {{ $t('faq.technical_questions') }}
        </button>
      </li>
      <li>
        <button
          :class="{ active: activeTab === 'financeQuestion' }"
          @click="selectTab('financeQuestion')"
        >
          {{ $t('faq.financial_questions') }}
        </button>
      </li>
    </ul>

    <div class="tubs-content" v-for="item in faq.common" :key="item.id">
      <div
        class="tubs-content__item"
        v-if="faq.common && activeTab === 'mainQuestion'"
      >
        <div class="faq-list faq">
          <div class="card faq__item">
            <div class="card-header faq__question" @click="toggleOpen">
              {{ faqTitle(item) }}
            </div>
            <div
              class="card-body faq__answer entry-content"
              v-html="faqText(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tubs-content" v-for="item in faq.technical" :key="item.id">
      <div
        class="tubs-content__item"
        v-if="faq.technical && activeTab === 'technicalQuestion'"
      >
        <div class="faq-list faq">
          <div class="card faq__item">
            <div class="card-header faq__question" @click="toggleOpen">
              {{ faqTitle(item) }}
            </div>
            <div
              class="card-body faq__answer entry-content"
              v-html="faqText(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tubs-content" v-for="item in faq.financial" :key="item.id">
      <div
        class="tubs-content__item"
        v-if="faq.financial && activeTab === 'financeQuestion'"
      >
        <div class="faq-list faq">
          <div class="card faq__item">
            <div class="card-header faq__question" @click="toggleOpen">
              {{ faqTitle(item) }}
            </div>
            <div
              class="card-body faq__answer entry-content"
              v-html="faqText(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <modal :width="780" name="createTicketModal" height="auto" :scrollable="true" classes="creative-modal" :shiftY="0">
      <CreateTicketModal @createdTicket="createdTicket" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import '@/assets/css/_tubs.scss'
import '@/assets/css/_no-information.scss'
import PanelService from '@/api/servicePanel'
import CreateTicketModal from "@/components/CreateTicketModal.vue";

export default {
  name: 'Faq',
  beforeMount() {
    document.title = `Partners.House | ${this.$t("faq.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("faq.title")}`;
  },
  data() {
    return {
      faq: {},
      activeTab: 'mainQuestion',
    }
  },
  mounted() {
    this.getFaq()
  },
  components:{
    CreateTicketModal
  },
  methods: {
    closeModal() {
      this.$modal.hide("createTicketModal");
    },
    askModal() {
      this.$modal.show("createTicketModal");
    },
    createdTicket(id) {
      this.$router.push(`/support-chat/${id}`);
    },
    selectTab(selectedTab) {
      this.activeTab = selectedTab
    },
    getFaq() {
      PanelService.getFaq().then((res) => {
        this.faq = res.data
      })
    },
    faqTitle(item) {
      return this.$i18n.locale === 'ru' ? item.title : item.title_en
    },
    faqText(item) {
      return this.$i18n.locale === 'ru' ? item.text : item.text_en
    },
    toggleOpen(event) {
      const content = event.target.nextSibling
      event.target.classList.toggle('active')
      if (content.style.maxHeight) {
        content.style.maxHeight = null
      } else {
        content.style.maxHeight = content.scrollHeight + 1.25 * 2 + 'rem'
      }
      event.target.nextSibling.classList.toggle('show')
    },
  },
}
</script>
<style lang="scss" scoped>
.faq__item {
  background: #f7f7f9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.faq__question {
  font-weight: 700;
  font-size: 16px;
  padding: 20px 60px 20px 30px;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
  }
  &:before {
    content: '';
    display: block;
    background: url('~@/assets/images/icons/down-dark.svg') center no-repeat;
    background-size: contain;
    width: 9px;
    height: 9px;
    color: #858793;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
  }
  &.active {
    &:before {
      transform: translateY(-50%) scale(1, -1);
      opacity: 1;
    }
  }
}
.faq__answer {
  padding: 0 30px 20px;
  max-width: 770px;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.faq-list {
  .card-header {
    cursor: pointer;
  }
  .card-body {
    transition: all 0.2s;
    max-height: 0;
    overflow: hidden;
    padding: 0 1.25rem;
    min-height: 0;
    &.show {
      padding: 1.25rem;
    }
  }
}
</style>
